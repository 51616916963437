<template>
  <Section 
  v-if="logos.length"
  class="pb-0 md:pb-16">
    <FadeIn delay="400">
      <Container>
        <div class="text-center">
          <div>
            Building <strong>trust and transparency</strong> with
          </div>
          <p class="text-subtitle" v-if="content.subtitle">{{ content.subtitle }}</p>
        </div>
      </Container>

      <Container>
        <div class="relative mx-auto mt-8 sm:mt-16">
          <div 
          class="absolute top-0 left-0 h-full w-[50px] md:w-[80px] lg-[100px] z-10"
          style="background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);"
          ></div>

          <div 
          class="absolute top-0 right-0 h-full w-[50px] md:w-[80px] lg-[100px] z-10"
          style="background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);"
          ></div>
          
          <Vue3Marquee
          :gradient="true"
          :gradient-color="[255, 255, 255]"
          gradient-length="25%"
          >
            <img 
            v-for="logo in logos" 
            :src="useCdn(logo.image.url)+'?width=200'" 
            :width="logo.image.width" 
            :height="logo.image.height" 
            :alt="`Trusted by Elker - ${logo.title}`"
            loading="lazy"
            class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6"
            >
          </Vue3Marquee>

        </div>
      </Container>
    </FadeIn>
  </Section>
</template>

<script setup>
import { Vue3Marquee } from 'vue3-marquee'
const config = useRuntimeConfig()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})

const logos = ref([])

const { data } = await useAsyncData(
  'logos',
  () => $fetch(`${config.public.strapiEndpoint}global?populate=deep`)
)

logos.value = data.value.data?.logos
</script>